var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-container',{staticStyle:{"height":"100%"},attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"subheading mt-2"},[_vm._v(" Select the tournaments that you would like to download ")])]),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"color":"color3","append-icon":"fas fa-search","label":"Search","hide-details":"","solo-inverted":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.rows,"options":_vm.listPage,"footer-props":{itemsPerPageOptions: [25, 50, -1]},"loading":_vm.loading,"loading-text":("Loading " + (_vm.page && _vm.page.name) + "'s tournaments..."),"search":_vm.search,"show-select":""},on:{"update:options":function($event){_vm.listPage=$event}},scopedSlots:_vm._u([{key:"progress",fn:function(){return [_c('v-progress-linear',{attrs:{"color":"color3","indeterminate":""}})]},proxy:true},{key:"item.sanctionedBy",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"95px"}},[_c('v-carousel',{staticClass:"elevation-0",attrs:{"show-arrows":false,"hide-delimiters":"","height":"40","max":"95","cycle":"","continuous":"","interval":"3000"}},_vm._l((item.logos),function(logo,i){return _c('v-carousel-item',{key:((logo.url) + "_" + i),class:logo.class,attrs:{"height":"40px","src":logo.url,"contain":""}})}),1)],1)]}},{key:"item.startDate",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.dateDisplay))]),_c('div',[_vm._v(_vm._s(item.year))])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"font-weight-thin"},[_vm._v(_vm._s(_vm._f("formatArray")(item.divisions)))])]}},{key:"item.public",fn:function(ref){
var item = ref.item;
return [(!item.public)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({},on),[_vm._v("fal fa-low-vision")])]}}],null,true)},[_c('span',[_vm._v("Only you can see this")])]):_vm._e()]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('v-fab-transition',[(_vm.selected.length)?_c('v-btn',{attrs:{"color":"color3 color3Text--text","fab":"","fixed":"","bottom":"","right":"","loading":_vm.loading},on:{"click":function($event){$event.stopPropagation();return _vm.download($event)}}},[_c('v-badge',{attrs:{"bordered":"","color":"error","overlap":"","content":_vm.selected.length,"offset-x":"-2","offset-y":"-2"}},[_c('v-icon',[_vm._v("fas fa-download")])],1)],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }