<template>
  <v-card flat>
    <v-container fluid style="height: 100%">
      <!-- BODY -->
        <v-row dense>
          <v-col cols="12">
            <p class="subheading mt-2">
              Select the tournaments that you would like to download
            </p>
          </v-col>
          <v-col cols="12">
            <v-text-field
              color="color3"
              append-icon="fas fa-search"
              label="Search"
              hide-details
              v-model="search"
              solo-inverted
              clearable
            ></v-text-field>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="rows"
          :options.sync="listPage"
          :footer-props="{itemsPerPageOptions: [25, 50, -1]}"
          :loading="loading"
          :loading-text="`Loading ${page && page.name}'s tournaments...`"
          :search="search"
          show-select
          v-model="selected"
        >
          <template v-slot:progress>
            <v-progress-linear color="color3" indeterminate></v-progress-linear>
          </template>
          <template v-slot:[`item.sanctionedBy`]="{item}">
              <div style="width:95px">
                <v-carousel
                  :show-arrows="false"
                  hide-delimiters
                  height="40"
                  max="95"
                  class="elevation-0"
                  cycle continuous
                  interval="3000"
                >
                  <v-carousel-item
                    height="40px"
                    v-for="(logo, i) in item.logos"
                    :key="`${logo.url}_${i}`"
                    :src="logo.url"
                    :class="logo.class"
                    contain
                  ></v-carousel-item>
                </v-carousel>
              </div>
          </template>
          <template v-slot:[`item.startDate`]="{item}">
            <div>{{ item.dateDisplay }}</div>
            <div>{{ item.year }}</div>
          </template>
          <template v-slot:[`item.name`]="{item}">
            <div class="font-weight-bold">{{ item.name }}</div>
            <div class="font-weight-thin">{{ item.divisions | formatArray }}</div>
          </template>
          <template v-slot:[`item.public`]="{item}">
            <v-tooltip right v-if="!item.public">
              <span>Only you can see this</span>
              <template v-slot:activator="{ on }">
                <v-icon v-on="on">fal fa-low-vision</v-icon>
              </template>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-fab-transition>
            <v-btn
              v-if="selected.length"
              color="color3 color3Text--text"
              fab
              fixed bottom right
              @click.stop="download"
              :loading="loading"
            >
              <v-badge
                bordered
                color="error"
                overlap
                :content="selected.length"
                offset-x="-2"
                offset-y="-2"
              >
                <v-icon>fas fa-download</v-icon>
              </v-badge>
            </v-btn>
        </v-fab-transition>
    </v-container>
  </v-card>
</template>

<script>
import Summary from '@/classes/TournamentSummary'
import { mapGetters } from 'vuex'
import { firstBy } from 'thenby'
import Papa from 'papaparse'

export default {
  props: ['username'],
  data () {
    return {
      loading: false,
      search: null,
      summaries: [],
      selected: [],
      listPage: { sortBy: ['startDate'], itemsPerPage: 25, sortDesc: [true] },
      teams: null
    }
  },
  computed: {
    ...mapGetters([
      'user',
      'getPageInfo'
    ]),
    page () {
      return this.getPageInfo(this.username)
    },
    headers () {
      return [
        { value: 'sanctionedBy', align: 'left', sortable: true },
        { text: 'Date', value: 'startDate', align: 'left', sortable: true },
        { text: 'Name', value: 'name', align: 'left', sortable: true },
        { text: 'Location', value: 'locations', align: 'left', sortable: true },
        { value: 'public', align: 'left', sortable: 'false' }
      ]
    },
    rows () {
      if (!this.summaries) return []
      var base = this.summaries.filter(f => !f.tags.includes('Series'))
      var result = base.map((t) => {
        return {
          id: t.id,
          startDate: t.timestamp,
          dateDisplay: t.startDate.isSame(t.endDate) ? t.startDate.format('MMM Do') : `${t.startDate.format('MMM Do')} - ${t.endDate.format('MMM Do')}`,
          dayDisplay: t.startDate.isSame(t.endDate) ? t.startDate.format('dddd') : `${t.startDate.format('ddd')} - ${t.endDate.format('ddd')}`,
          name: t.name,
          locations: t.locationsString,
          public: t.isPublic,
          regOpen: t.regOpen,
          sanctionedBy: t.sanctionedBy || '',
          admin: this.user && this.user.isTournamentAdmin(t),
          ibvl: t.ibvl,
          logos: t.logos.filter(f => f && f.url) || [],
          divisions: (t.divisionNames || []).sort(),
          tournament: t,
          tags: t.tags,
          year: t.startDate.format('YYYY')
        }
      })
      if (!result.length) return [{ x: 'filler' }]
      return result
    },
    userIsAdmin () {
      return this.user && this.user.isPageAdmin(this.username)
    },
    dto () {
      return {
        ids: this.selected.map(m => m.id)
      }
    },
    teamData () {
      const result = this.teams.map(team => {
        const obj = {
          Tournament: team.tournament,
          Division: team.division,
          'Team Name': team.team,
          Status: team.isDeleted ? 'WITHDREW' : team.waitlist ? 'WAITLIST' : 'REGISTERED',
          Finish: team.finish
        }
        team.players.forEach((player, i) => {
          player.fields.forEach(field => {
            obj[`Player ${i + 1} ${field.label}`] = field.value
          })
          obj[`Player ${i + 1} Id`] = player.i
        })
        obj.Id = team.id
        return obj
      })
      result.sort(firstBy((a, b) => { return Object.keys(b).length - Object.keys(a).length }))
      return result
    }
  },
  methods: {
    getList () {
      this.loading = true
      const query = `active=false&organizationUserName=${this.username}&withCounts=true&coOp=false`
      this.$VBL.tournament.getAllSummaries(query)
        .then(r => {
          this.summaries = r.data.map(s => new Summary(s))
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => {
          this.loading = false
        })
    },
    download () {
      this.loading = true
      this.$VBL.organization.registrationFields(this.dto)
        .then(response => {
          this.teams = response.data
          this.postDownload()
        })
        .catch(err => console.log(err.response))
        .finally(() => { this.loading = false })
    },
    createFileForDownload (data, name) {
      var opt = { header: !this.avpaFormat }
      var csv = Papa.unparse(data, opt)
      var blob = new Blob([csv])
      var a = window.document.createElement('a')
      a.href = window.URL.createObjectURL(blob, { type: 'text/plain' })
      a.download = name
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    },
    postDownload (coaches) {
      var fileName = `${this.page.name.replace(/ /g, '_')}_Team_Export.csv`
      var data = this.teamData
      this.createFileForDownload(data, fileName)
    }
  },
  watch: {
    username: function (val) {
      this.getList()
    }
  },
  mounted () {
    this.getList()
  }
}
</script>

<style scoped>
  h2.lined {
    border-bottom: 1px solid grey;
    line-height: 0.1em;
  }
  h2.lined span {
    padding: 0 10px;
    background: #fff
  }

</style>
